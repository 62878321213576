import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";

import Layout from "../../components/layout";
import Images from "../../components/imageManagement/";
// import queryString from 'query-string'

const Index = () => {
  return (
    <Layout>
      <Images />
    </Layout>
  );
};

export default Index;
